import './index.scss'
$(window).scroll(function (event) {
  checkscroll()
});
function checkscroll() {
  var winPos = $(window).scrollTop(); //屏幕位置
  var NodePos = [$('#model0').offset().top, $('#model1').offset().top, $('#model2').offset().top, $('#model3').offset().top, $('#model4').offset().top],//提前获取好元素位置
    length = NodePos.length;
  //console.log(NodePos)
  if (winPos <= NodePos[1]) {
    $('.nav ul li').removeClass('active');
    $('.nav ul li:nth-child(1)').addClass('active');
  } else {
    for (var i = 1; i < length; i++) {
      if ((i < length - 1 && winPos < NodePos[i + 1] && winPos >= NodePos[i]) || (i == length - 1 && winPos <= NodePos[i])) {
        $('.nav ul li').removeClass('active');
        $('.nav ul li:nth-child(' + (i + 1) + ')').addClass('active');
        break;
      }
    }
  }
}