module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<footer>\r\n    <div class="container">\r\n        <div class="footer">\r\n            <div class="copyright"> 扑克世界官方版权所有-World of Poker Official Website Copyright </div>\r\n            <div class="down mydialog">\r\n                <ul>\r\n                    <li class="ewmShow">\r\n                        <img src="' +
((__t = (require('./images/icon4.png'))) == null ? '' : __t) +
'" alt="">\r\n                        <span>IOS下载</span>\r\n                    </li>\r\n                    <li class="ewmShow">\r\n                        <img src="' +
((__t = (require('./images/icon7.png'))) == null ? '' : __t) +
'" alt="">\r\n                        <span>安卓下载</span>\r\n                    </li>\r\n                </ul>\r\n            </div>\r\n        </div>\r\n    </div>\r\n</footer>';

}
return __p
}