module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<header>\r\n  <div class="container">\r\n    <div class="logo">\r\n      <img src="' +
((__t = (require('./images/logo.png'))) == null ? '' : __t) +
'" class="pc-logo" alt="">\r\n      <img src="' +
((__t = (require('./images/mob-logo.png'))) == null ? '' : __t) +
'" class="mob-logo" alt="">\r\n    </div>\r\n    <div class="nav">\r\n      <ul>\r\n        <li class="active"><a href="#model0">首页</a></li>\r\n        <li><a href="#model1">优势</a></li>\r\n        <li><a href="#model2">下载</a></li>\r\n        <li><a href="#model3">反馈</a></li>\r\n        <li><a href="#model4">APP</a></li>\r\n      </ul>\r\n    </div>\r\n  </div>\r\n</header>';

}
return __p
}